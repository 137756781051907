// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/* @font-face {
  font-family: 'CS Font';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/JosefinSans-Regular.ttf');
}
 */
/** Ionic CSS Variables **/

:root {
  /* --ion-font-family: 'CS Font'; */

    /* These examples use the same color: sienna. */
    --ion-text-color: #666;
    // --ion-text-color-rgb:         160, 82, 45;

    /* These examples use the same color: lightsteelblue. */
    // --ion-background-color:       #b0c4de;
    // --ion-background-color-rgb:   176, 196, 222;

  --ion-color-primary: #990033;
  --ion-color-primary-rgb: 153, 0, 51;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #87002d;
  --ion-color-primary-tint: #a31a47;

  --ion-color-secondary: #063b5b;
  --ion-color-secondary-rgb: 6, 59, 91;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #053450;
  --ion-color-secondary-tint: #1f4f6b;

  --ion-color-tertiary: #ebeced;
  --ion-color-tertiary-rgb: 235, 236, 237;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #cfd0d1;
  --ion-color-tertiary-tint: #edeeef;

  --ion-color-success: #21ad6f;
  --ion-color-success-rgb: 33, 173, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #1d9862;
  --ion-color-success-tint: #37b57d;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-primary_opacity: rgba(153, 0, 51, 0.5);

  --ion-color-light-opacity: rgba(244, 245, 248, 0.8);
  .ion-color-light-opacity {
    --ion-color-base: var(--ion-color-white-opacity) !important;
  }

  --ion-color-medium-opacity: rgba(152, 154, 162, 0.8);
  .ion-color-medium-opacity {
    --ion-color-base: var(--ion-color-medium-opacity) !important;
  }
}

// @media (prefers-color-scheme: dark) {
//   :root {
//     /* Dark mode variables go here */

//     --ion-background-color: #ffffff;
//     --ion-background-color-rgb: 255, 255, 255;

//     --ion-text-color: #000000;
//     --ion-text-color-rgb: 0, 0, 0;

//     --ion-color-step-50: #f2f2f2;
//     --ion-color-step-100: #e6e6e6;
//     --ion-color-step-150: #d9d9d9;
//     --ion-color-step-200: #cccccc;
//     --ion-color-step-250: #bfbfbf;
//     --ion-color-step-300: #b3b3b3;
//     --ion-color-step-350: #a6a6a6;
//     --ion-color-step-400: #999999;
//     --ion-color-step-450: #8c8c8c;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #737373;
//     --ion-color-step-600: #666666;
//     --ion-color-step-650: #595959;
//     --ion-color-step-700: #4d4d4d;
//     --ion-color-step-750: #404040;
//     --ion-color-step-800: #333333;
//     --ion-color-step-850: #262626;
//     --ion-color-step-900: #191919;
//     --ion-color-step-950: #0d0d0d;
//   }
// }
