ion-card-title {
  font-size: 22px;
  font-weight: normal !important;
  color: var(--ion-color-primary) !important;
}
iframe {
  width: 100% !important;
  height: 240px !important;
    // pointer-events: none;
    // cursor: default;
}
.center-spinner {
  --color: var(--ion-color-primary) !important;
  //   fill: color($colors, primary) !important;
  position: fixed;
  z-index: 999;
  height: 4em;
  width: 4em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

h1 {
  font-weight: normal !important;
}

.title {
  font-weight: bolder;
  color: var(--ion-color-dark-tint);
}
.description {
  background-color: var(--ion-color-light);
  padding: 2vw;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
}

.bigger-font {
  font-size: 1.2em;
  font-weight: 400 !important;
  color: #555555;
  line-height: 160%;
  b {
    font-weight: 400;
  }
}

ion-badge {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 1.1em;
  background-color: transparent;
  padding: 8px;
  border: 1px solid var(--ion-color-secondary-tint);
  color: var(--ion-color-secondary-tint);
}

hr {
  height: 1px;
  background-color: var(--ion-color-light-shade);
}

.header-title {
  ion-list-header {
    ion-label {
      background-color: var(--ion-color-light-shade);
      font-size: 14px;
      display: block;
      width: 100%;
      padding: 5px 15px;
    }
  }
}

.routerLink:focus {
  outline: 0;
}
